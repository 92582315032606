import { Box, Grid, Typography } from "@mui/material";
import { Site } from "../../app/models/site";
import SiteCard from "./SiteCard";

interface Props {
  sites: Site[];
}

export default function SiteList({ sites }: Props) {
  return sites.length === 0 ? (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">No sites found</Typography>
    </Box>
  ) : (
    <Grid container spacing={2}>
      {sites.map((site) => (
        <Grid item xs={4} key={site.id}>
          <SiteCard site={site} />
        </Grid>
      ))}
    </Grid>
  );
}
