import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      onClick={() => navigate(-1)}
      startIcon={<ArrowBackIcon />}
      sx={{ mb: 3, borderRadius: "8px" }}
    >
      Back
    </Button>
  );
}
