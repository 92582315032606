import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const sleep = () => new Promise((resolve) => setTimeout(resolve, 500));

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const session = Cookies.get("__session");
  if (session) {
    config.headers.Authorization = `Bearer ${session}`;
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    await sleep();
    return response;
  },
  (error: AxiosError) => {
    const { data, status } = error.response as any;
    switch (status) {
      case 400:
        if (data.errors) {
          const modelStateErrors: string[] = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateErrors.push(data.errors[key]);
            }
          }
          throw modelStateErrors.flat();
        }
        toast.error(data.title);
        break;
      case 401:
        toast.error(data.title);
        break;
      case 403:
        toast.error("You are not allowed to do that!");
        break;
      case 500:
        toast.error(data.title);
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  }
);

const requests = {
  get: (url: string, params?: URLSearchParams) =>
    axios.get(url, { params }).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
};

const Catalog = {
  list1: (params: URLSearchParams) => requests.get("Sites", params),
  list: () => requests.get(`Sites`),
  details: (id: string) => requests.get(`Sites/${id}`),
  fetchFilters: () => requests.get("Sites/filters"),
  addSite: (values: any) => requests.post("Sites", values),
  enableSite: (id: string) => requests.get(`Sites/Enable/${id}`),
  disableSite: (id: string) => requests.get(`Sites/Disable/${id}`),
  deploySite: (id: string) => requests.get(`Sites/Deploy/${id}`),
  getLogs: (id: string) => requests.get(`Sites/getLog/${id}`),
  addHacker: (values: any) => requests.post("Sites/AddHacker", values),
  removeHacker: (values: any) => requests.post("Sites/RemoveHacker", values),
  allowedHackerType: (values: any) =>
    requests.post("Sites/AllowedHackerType", values),
  getHackers: (id: string) => requests.get(`Sites/getHackers/${id}`),
  startSite: (values: any) => requests.post("Sites/Start", values),
  stopSite: (values: any) => requests.post("Sites/Stop", values),
  credentials: (values: any) => requests.post("Sites/Credentials", values),
  instructions: (values: any) => requests.post("Sites/Instructions", values),
};

const Tenant = {
  listMembers: () => requests.get(`Tenants/members`),
  addMember: (values: any) => requests.post("Tenants/addMember", values),
  revokeMember: (values: any) => requests.post("Tenants/revokeMember", values),
  createTenant: (values: any) => requests.post("Tenants", values),
};

const Billing = {
  details: () => requests.get(`Billing`),
  update: (values: any) => requests.post("Billing", values),
};

const TestErrors = {
  get400Error: () => requests.get("buggy/bad-request"),
  get401Error: () => requests.get("buggy/unauthorised"),
  get404Error: () => requests.get("buggy/not-found"),
  get500Error: () => requests.get("buggy/server-error"),
  getValidationError: () => requests.get("buggy/validation-error"),
};

const Account = {
  login: (values: any) => requests.post("account/login", values),
  register: (values: any) => requests.post("account/register", values),
  currentUser: () => requests.get("account/currentUser"),
  forgotPassword: (values: any) =>
    requests.post("account/forgotPassword", values),
  resetPassword: (values: any) =>
    requests.post("account/resetPassword", values),
  confrimEmail: (values: any) => requests.post("account/confirmEmail", values),
  changePassword: (values: any) =>
    requests.post("account/changePassword", values),
  resendEmailConfirm: (values: any) =>
    requests.get("account/resendEmailConfirmationLink", values),
  b2cLogin: (values: any) => requests.post("account/b2cLogin", values),
};

const Analytics = {
  getAnalytics: (values: any) =>
    requests.get("Analytics/getTotalRequest", values),
  getMostActiveUsers: (values: any) =>
    requests.get("Analytics/getMostActiveUsers", values),
  getTrafficTable: (values: any) =>
    requests.get("Analytics/getTrafficTable", values),
  getPathDiscovery: (values: any) =>
    requests.get("Analytics/getPathDiscovery", values),
  getTrafficTimeline: (values: any) =>
    requests.get("Analytics/getTrafficTimeline", values),
  getWAFMessages: (values: any) =>
    requests.get("Analytics/getWAFMessages", values),
};

const agent = {
  Catalog,
  TestErrors,
  Account,
  Tenant,
  Billing,
  Analytics,
};

export default agent;
