import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import Layout from "../../app/layout/Layout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { BillingData } from "../../app/models/billing";
import { Site } from "../../app/models/site";
import { useOrganization } from "@clerk/clerk-react";

export default function Billing() {
  const [billingData, setBillingData] = useState<BillingData>();
  const [loading, setLoading] = useState(true);
  const { organization } = useOrganization();

  const [sites, setSites] = useState<Site[]>([]);

  useEffect(() => {
    agent.Billing.details()
      .then((billingData) => setBillingData(billingData))
      .catch((error) => console.log(error));

    agent.Catalog.list()
      .then((sites) => setSites(sites))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [organization]);

  if (loading) return <LoadingComponent message="Loading billing data..." />;

  return (
    <Layout>
      <Typography variant="h4" mb={4}>
        Billing details
      </Typography>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
          <Box sx={{ fontWeight: 'bold' }}> HackGATE™ Credits </Box>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body1">
                Available credits: 0
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                component={Link}
                to="/buy"
                disabled
                variant="outlined"
                sx={{borderRadius: "8px"}}
              >
                Buy credit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
          <Box sx={{ fontWeight: 'bold' }}> Subscriptions </Box>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={7}>
            {sites.length === 0 ? <Typography variant="body1">You have no configured websites.</Typography> : ""}
              {sites.map((site) => (
                <Typography variant="body1">{site.originName}</Typography>
              ))}
            </Grid>
            <Grid item xs={3}>
              {sites.map((site) => (
                <Typography variant="body1">HackGATE™ Beta Plan</Typography>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
          <Box sx={{ fontWeight: 'bold' }}> Billing email preference </Box>
          </Typography>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body1">
                {billingData?.email && billingData.email}
                {!billingData?.email &&
                  "You don't set any billing email address."}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button component={Link} to="/billing/update" variant="outlined" sx={{borderRadius: "8px"}}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
          <Box sx={{ fontWeight: 'bold' }}> Billing address </Box>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body1">
                {billingData?.address && (
                  <p>
                    {billingData.bussinessName}
                    <br />
                    {billingData.address}
                    <br />
                    {billingData.addressLine2}
                    <br />
                    {billingData.city}
                    <br />
                    {billingData.zipCode}
                    <br />
                    {billingData.country}
                    <br />
                    {billingData.state}
                  </p>
                )}
                {!billingData?.address && "You don't set any billing address."}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button component={Link} to="/billing/update" variant="outlined" sx={{borderRadius: "8px"}}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
          <Box sx={{ fontWeight: 'bold' }}> Billable usage notification </Box>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body1">
                Configure a billable usage notification to monitor usage and
                avoid surprises on your bill. Note: This threshold value will
                not set a cap on usage.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
          <Box sx={{ fontWeight: 'bold' }}> Invoices & Documents </Box>
          </Typography>
          <Typography variant="body1" mb={3}>
            If you are an Azure Marketplace user, please visit the marketplace
            you used to sign up for HackGATE (Azure) to manage your invoices.
          </Typography>
          <Typography variant="body1">
            You don't have any invoices yet.
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
          <Box sx={{ fontWeight: 'bold' }}>Payment</Box>  
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body1" mb={3}>
                If you are an Azure Marketplace user, please visit the
                marketplace you used to sign up for HackGATE (Azure) to manage
                your payment information.
              </Typography>
              <Typography variant="body1">
                You have not added a payment method.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                component={Link}
                to="/billing/update"
                disabled
                variant="outlined"
                sx={{borderRadius: "8px"}}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
}
