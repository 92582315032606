import { SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Layout from "../../app/layout/Layout";
import { Site } from "../../app/models/site";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import MyChart from "./MyChart";
import MyChart2 from "./MyChart2";
import MyChart3 from "./MyChart3";
import MyChart4 from "./MyChart4";
import MyChart5 from "./MyChart5";
import { useOrganization } from "@clerk/clerk-react";
import {
  EuiEmptyPrompt,
  EuiLink,
  EuiSelect,
  EuiSpacer,
  EuiSuperDatePicker,
  EuiSuperDatePickerProps,
  EuiTitle,
  OnRefreshProps,
  OnTimeChangeProps,
} from "@elastic/eui";
import { EuiPageHeader, EuiButton } from "@elastic/eui";
import MyChart6 from "./MyChart6";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Analytics() {
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState<Site[]>([]);
  const { organization } = useOrganization();

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const [recentlyUsedRanges, setRecentlyUsedRanges] = useState<
    NonNullable<EuiSuperDatePickerProps["recentlyUsedRanges"]>
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [start, setStart] = useState("now-12h");
  const [end, setEnd] = useState("now");

  const onTimeChange = ({ start, end }: OnTimeChangeProps) => {
    const recentlyUsedRange = recentlyUsedRanges.filter((recentlyUsedRange) => {
      const isDuplicate =
        recentlyUsedRange.start === start && recentlyUsedRange.end === end;
      return !isDuplicate;
    });
    recentlyUsedRange.unshift({ start, end });
    setStart(start);
    setEnd(end);
    setRecentlyUsedRanges(
      recentlyUsedRange.length > 10
        ? recentlyUsedRange.slice(0, 9)
        : recentlyUsedRange
    );
    setIsLoading(true);
    startLoading();
  };

  const onRefresh = ({ start, end, refreshInterval }: OnRefreshProps) => {
    return new Promise((resolve) => {
      setTimeout(resolve, 100);
    }).then(() => {
      console.log(start, end, refreshInterval);
    });
  };

  const startLoading = () => {
    setTimeout(stopLoading, 1000);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    agent.Catalog.list()
      .then((sites) => setSites(sites))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [organization]);

  const siteArray = sites.map((site) => site.hackgatedName);

  if (loading) return <LoadingComponent message="Loading website data..." />;

  if (sites.length === 0)
    return (
      <Layout>
        <EuiPageHeader
          pageTitle="Analytics"
          // iconType="logoKibana"
          description="Analytics dashboards are designed to help you to gain better undertaning of you pentest projects by providing oversight of ethical hacker activity."
          // rightSideItems={[
          //   <EuiButton fill>Add something</EuiButton>,
          //   <EuiButton>Do something</EuiButton>,
          // ]}
          paddingSize="none"
        />
        <EuiSpacer />

        <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
          <CardContent>
            <Box
              sx={{
                height: 400,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EuiEmptyPrompt
                actions={[
                  <EuiButton color="primary" fill>
                    Add a website
                  </EuiButton>,
                ]}
                body={<p>Add a new case or change your filter settings.</p>}
                color="subdued"
                // iconType="logoSecurity"
                layout="vertical"
                title={<h2>Start adding websites</h2>}
                titleSize="m"
              />
            </Box>
          </CardContent>
        </Card>
      </Layout>
    );

  return (
    <Layout>
      <EuiPageHeader
        pageTitle="Analytics"
        // iconType="logoKibana"
        description="Analytics dashboards are designed to help you to gain better undertaning of you pentest projects by providing oversight of ethical hacker activity."
        // rightSideItems={[
        //   <EuiButton fill>Add something</EuiButton>,
        //   <EuiButton>Do something</EuiButton>,
        // ]}
        paddingSize="none"
      />
      <EuiSpacer />

      {/* <EuiSelect
        options={siteArray.map((hackgatedName, index) => ({ value: hackgatedName, text: hackgatedName }))}
        onChange={(e) => {
          // Handle the select change event
          console.log('Selected value:', e.target.value);
        }}
      /> */}

      <Grid container alignItems="center" spacing={3} mb={3}>
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="site-select-label">Select a site</InputLabel>
            <Select
              labelId="site-select-label"
              id="site-select"
              value={selectedOption}
              label="Select a site"
              onChange={handleOptionChange}
              sx={{borderRadius: "8px"}}
            >
              {sites.map((sites) => (
                <MenuItem key={sites.hackgatedName} value={sites.hackgatedName}>
                  {sites.hackgatedName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <EuiSuperDatePicker onTimeChange={onTimeChange}  start={start}
        end={end} />
        </Grid>
      </Grid>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          {selectedOption === "" ? (
            <Box
              sx={{
                height: 400,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EuiEmptyPrompt
                // iconType="logoSecurity"
                title={<h2>Select a website</h2>}
                body={<p>Select or add a new website or change your filter settings.</p>}
                actions={
                  <Button
          component={Link}
          to="/catalog/add-site"
          variant="contained"
          startIcon={<Add />}
        >
          Add a website
        </Button>
                }
                footer={
                  <>
                    <EuiTitle size="xxs">
                      <h3>Want to learn more?</h3>
                    </EuiTitle>
                    <EuiLink href="https://www.hackgate.io/how-does-hackgate-work" target="_blank">
                      Read the docs
                    </EuiLink>
                  </>
                }
              />
            </Box>
          ) : (
            ""
          )}

          {selectedOption !== "" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined" sx={{borderRadius: "12px"}}>
                  <CardContent>
                    <MyChart6 url={selectedOption} gte={start} lte={end} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card variant="outlined" sx={{borderRadius: "12px"}}>
                  <CardContent>
                    <MyChart4 url={selectedOption} gte={start} lte={end} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card variant="outlined" sx={{borderRadius: "12px"}}>
                  <CardContent>
                    <MyChart2 url={selectedOption} gte={start} lte={end} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card variant="outlined" sx={{borderRadius: "12px"}}>
                  <CardContent>
                    <MyChart3 url={selectedOption} gte={start} lte={end} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card variant="outlined" sx={{borderRadius: "12px"}}>
                  <CardContent>
                    <MyChart5 url={selectedOption} gte={start} lte={end} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card variant="outlined" sx={{borderRadius: "12px"}}>
                  <CardContent>
                    <MyChart url={selectedOption} gte={start} lte={end} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </Layout>
  );
}
