import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Site, SiteParams } from "../../app/models/site";
import { RootState } from "../../app/store/configureStore";

interface CatalogState {
    sitesLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    siteParams: SiteParams;
    metaData: MetaData | null;
}

const sitesAdapter = createEntityAdapter<Site>();

function getAxiosParams(siteParams: SiteParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', siteParams.pageNumber.toString());
    params.append('pageSize', siteParams.pageSize.toString());
    params.append('orderBy', siteParams.orderBy);
    if (siteParams.searchTerm) params.append('searchTerm', siteParams.searchTerm);
    return params;
}

export const fetchSitesAsync = createAsyncThunk<Site[], void, {state: RootState}>(
    'catalog/fetchSitesAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().catalog.siteParams);
        try {
            const response = await agent.Catalog.list();
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const fetchSiteAsync = createAsyncThunk<Site, string>(
    'catalog/fetchSiteAsync',
    async (siteId, thunkAPI) => {
        try {
            return await agent.Catalog.details(siteId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const enableSite = createAsyncThunk<Site, string>(
    'catalog/enableSite',
    async (siteId, thunkAPI) => {
        try {
            return await agent.Catalog.enableSite(siteId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const disableSite = createAsyncThunk<Site, string>(
    'catalog/disableSite',
    async (siteId, thunkAPI) => {
        try {
            return await agent.Catalog.disableSite(siteId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const deploySite = createAsyncThunk<Site, string>(
    'catalog/deploySite',
    async (siteId, thunkAPI) => {
        try {
            return await agent.Catalog.deploySite(siteId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const fetchFilters = createAsyncThunk(
    'catalog/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Catalog.fetchFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const addSite = createAsyncThunk<string, FieldValues>(
    'catalog/addSite',
    async (data, thunkAPI) => {
        try {
            const site = await agent.Catalog.addSite(data);
            return site;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const credentials = createAsyncThunk<string, FieldValues>(
    'catalog/credentials',
    async (data, thunkAPI) => {
        try {
            const site = await agent.Catalog.credentials(data);
            return site;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const instructions = createAsyncThunk<string, FieldValues>(
    'catalog/instructions',
    async (data, thunkAPI) => {
        try {
            const site = await agent.Catalog.instructions(data);
            return site;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)


export const startSite = createAsyncThunk<string, FieldValues>(
    'catalog/startSite',
    async (data, thunkAPI) => {
        try {
            const site = await agent.Catalog.startSite(data);
            return site;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const stopSite = createAsyncThunk<string, FieldValues>(
    'catalog/stopSite',
    async (data, thunkAPI) => {
        try {
            const site = await agent.Catalog.stopSite(data);
            return site;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const addHacker = createAsyncThunk<string, FieldValues>(
    'catalog/addHacker',
    async (data, thunkAPI) => {
        try {
            const site = await agent.Catalog.addHacker(data);
            return site;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)



function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'name',
    }
}

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState: sitesAdapter.getInitialState<CatalogState>({
        sitesLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        siteParams: initParams(),
        metaData: null
    }),
    reducers: {
        setSiteParams: (state, action) => {
            state.sitesLoaded = false;
            state.siteParams = {...state.siteParams, ...action.payload, pageNumber: 1};
        },
        setPageNumber: (state, action) => {
            state.sitesLoaded = false;
            state.siteParams = {...state.siteParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetSiteParams: (state) => {
            state.siteParams = initParams();
        },
        setSite: (state, action) => {
            sitesAdapter.upsertOne(state, action.payload);
            state.sitesLoaded = false;
        },
        removeSite: (state, action) => {
            sitesAdapter.removeOne(state, action.payload);
            state.sitesLoaded = false;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchSitesAsync.pending, (state) => {
            state.status = 'pendingFetchSites';
        });
        builder.addCase(fetchSitesAsync.fulfilled, (state, action) => {
            sitesAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.sitesLoaded = true;
        });
        builder.addCase(fetchSitesAsync.rejected, (state, action) => {
            console.log(action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchSiteAsync.pending, (state) => {
            state.status = 'pendingFetchSite';
        });
        builder.addCase(fetchSiteAsync.fulfilled, (state, action) => {
            sitesAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchSiteAsync.rejected, (state, action) => {
            console.log(action);
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.pending, (state) => {
            state.status = 'pendingFetchFilters';
        });
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            state.filtersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.rejected, (state, action) => {
            state.status = 'idle';
            console.log(action.payload);
        });
        builder.addCase(enableSite.fulfilled, (state, action) => {
            sitesAdapter.upsertOne(state, action.payload);
        });
        builder.addCase(disableSite.fulfilled, (state, action) => {
            sitesAdapter.upsertOne(state, action.payload);
        });
    })
})

export const siteSelectors = sitesAdapter.getSelectors((state: RootState) => state.catalog);

export const {setSiteParams, resetSiteParams, setMetaData, setPageNumber, setSite, removeSite} = catalogSlice.actions;