import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { fetchSiteAsync, siteSelectors } from "./catalogSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import Layout from "../../app/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import agent from "../../app/api/agent";
import BackButton from "../../app/layout/BackButton";
import { Log } from "../../app/models/log";
import { EuiBreadcrumb, EuiBreadcrumbs, EuiSpacer } from "@elastic/eui";

export default function LogView() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const site = useAppSelector((state) => siteSelectors.selectById(state, id!));
  const { status: siteStatus } = useAppSelector((state) => state.catalog);

  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!site) dispatch(fetchSiteAsync(id!));
  }, [id!, site, dispatch]);

  useEffect(() => {
    agent.Catalog.getLogs(id!)
      .then((logs) => setLogs(logs))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  if (siteStatus.includes("pending"))
    return <LoadingComponent message="Loading website..." />;

  if (!site) return <h3>Website not found</h3>;

  const columns: GridColDef[] = [
    { field: "date", headerName: "Time (UTC)", flex: 1 },
    { field: "action", headerName: "Status", flex: 1 },
  ];

  const rows = logs;

  const breadcrumbs: EuiBreadcrumb[] = [
    {
      text: 'Websites',
      href: 'catalog/',
      onClick: (e) => {
        e.preventDefault();
      },
      'data-test-subj': 'breadcrumbsAnimals',
    },
    {
      text: site?.originName,
      href: '/',
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      text: 'Logs',
      href: '#',
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ];

  return (
    <Layout>
      <BackButton />
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />
      <EuiSpacer size="xl" />
      <Typography variant="h4" mb={2}>
        Running Log
      </Typography>
      <Grid>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            sx={{borderRadius: "12px"}}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Grid>
    </Layout>
  );
}
