import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import LoadingComponent from "../../app/layout/LoadingComponent";
import {
  deploySite,
  disableSite,
  enableSite,
  fetchSiteAsync,
  siteSelectors,
} from "./catalogSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { LoadingButton } from "@mui/lab";
import Layout from "../../app/layout/Layout";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ContentCopyOutlined,
  ArrowCircleRightOutlined,
  Block,
  CheckCircleOutline,
  LaunchOutlined,
} from "@mui/icons-material";
import {
  EuiPageHeader,
  EuiIcon,
  EuiSpacer,
  EuiLink,
  EuiIconTip,
} from "@elastic/eui";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BackButton from "../../app/layout/BackButton";
import dayjs from "dayjs";

export default function SiteDetails() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const site = useAppSelector((state) => siteSelectors.selectById(state, id!));
  const { status: siteStatus } = useAppSelector((state) => state.catalog);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!site) dispatch(fetchSiteAsync(id!));
  }, [id, site, dispatch]);

  async function submitDisable(id: string) {
    try {
      setSubmitting(true);
      await dispatch(disableSite(id));
      setSubmitting(false);
      toast.success("Site stopped successfully");
    } catch (error) {
      console.log("[Login] login user");
    }
  }

  async function submitEnable(id: string) {
    try {
      setSubmitting(true);
      await dispatch(enableSite(id));
      setSubmitting(false);
      toast.success("Site started successfully");
    } catch (error) {
      console.log("[Login] login user");
    }
  }

  async function submitDeploy(id: string) {
    try {
      setSubmitting(true);
      await dispatch(deploySite(id));
      setSubmitting(false);
    } catch (error) {
      console.log("[Login] login user");
    }
  }

  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const handleCopy = () => {
    const textFieldValue = textFieldRef.current?.value;
    if (textFieldValue) {
      navigator.clipboard.writeText(textFieldValue);
      toast.success("Copied to clipboard");
    }
  };

  if (siteStatus.includes("pending"))
    return <LoadingComponent message="Loading website..." />;

  if (!site) return <h3>Website not found</h3>;

  return (
    <Layout>
      <BackButton/>
      <EuiPageHeader
        pageTitle="Overview"
        // rightSideItems={[
        //   <EuiButton fill>Add something</EuiButton>,
        //   <EuiButton>Do something</EuiButton>,
        // ]}
      />
      <EuiSpacer />

      <Grid
        container
        spacing={2}
        sx={{ mb: 3 }}
        alignContent={"center"}
        justifyContent={"center"}
      >
        <Grid item xs={2}>
          {site.isDeploymentProgress ? (
            <Card
              variant="outlined"
              sx={{ borderColor: "warning.main", borderRadius: "12px" }}
              style={{ height: "100%" }}
            >
              <CardContent sx={{ paddingTop: 3 }}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CircularProgress color="warning" />
                  </Grid>
                  <Grid item xs={8}>
                  <Box sx={{ fontWeight: 'bold' }}>  <Typography>Deployment is in progress</Typography> </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <Card variant="outlined" style={{ height: "100%" , borderRadius: "12px" }}>
              <CardContent sx={{ paddingTop: 3 }}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CheckCircleOutline fontSize="large" color="success" />
                  </Grid>
                  <Grid item xs={8}>
                  <Box sx={{ fontWeight: 'bold' }}>  <Typography>Deployment is ready</Typography> </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={10}>
          <Card variant="outlined" sx={{borderRadius: "12px"}}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="body1" color="grey.700">
                    HackGATE™ URL
                  </Typography>
                  <Typography variant="h5" color="primary">
                    {site.hackgatedName}
                    <IconButton
                      aria-label="link"
                      href={`https://${site.hackgatedName}`}
                      target="blank"
                    >
                      <LaunchOutlined />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {site.isActive === true && (
                    <ArrowCircleRightOutlined
                      sx={{ fontSize: 50 }}
                      color="success"
                    />
                  )}
                  {site.isActive === false && (
                    <Block sx={{ fontSize: 50 }} color="error" />
                  )}
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1" color="grey.700">
                    ORIGIN
                  </Typography>
                  <Typography variant="h5">
                    {site.originName}
                    <IconButton
                      aria-label="link"
                      href={site.originName}
                      target="blank"
                    >
                      <LaunchOutlined />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    {site.isActive === true && (
                      <Typography color="green">Active</Typography>
                    )}
                    {site.isActive === false && (
                      <>
                        <Typography color="red">Disabled</Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    HackGATE™ Instance IP Address
                    <Tooltip title="This will be the IP address of your instance, that will communicate with your website.">
                      <IconButton>
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography>20.203.161.239</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Health check</TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Button disabled>Run</Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Allowed Ethical Hackers</TableCell>
                  <TableCell>
                    <Typography>
                      {site.hasCustomList === false
                        ? "Everyone"
                        : "Custom List"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to="hackers" variant="outlined" sx={{borderRadius: "8px"}}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Scheduled start</TableCell>
                  <TableCell>
                    <Typography>
                      {site.startDate.toString() === "0001-01-01T00:00:00"
                        ? "Not set"
                        : site.startDate.toString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to="start" variant="outlined" sx={{borderRadius: "8px"}}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Scheduled stop</TableCell>
                  <TableCell>
                    <Typography>
                      {site.stopDate.toString() === "0001-01-01T00:00:00"
                        ? "Not set"
                        : site.stopDate.toString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to="stop" variant="outlined" sx={{borderRadius: "8px"}}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Credentials for testing
                  <Tooltip  title="Credentials help us testing your HackGATE deployment if the origin has authentication enabled (username - password, Basic authentication)">
                      <IconButton>
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                  <Typography>
                      {site.credentials === null || site.credentials === ""
                        ? "Not set"
                        : "Set"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to="credentials" variant="outlined" sx={{borderRadius: "8px"}}>
                      edit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Instructions
                  <Tooltip title="Any useful information that helps us when we will test your HackGATE deployment">
                      <IconButton>
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {site.instructions === null || site.instructions === ""
                        ? "Not set"
                        : "Set"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to="instructions" variant="outlined" sx={{borderRadius: "8px"}}>
                      edit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Running log</TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Button component={Link} to="log" variant="outlined" sx={{borderRadius: "8px"}}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created at (UTC)</TableCell>
                  <TableCell>
                        {dayjs(site.creationdDate).format('MM/DD/YYYY hh:mm')}
                  </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
            <CardContent>
              <Typography variant="h6" mb={2}>
              <Box sx={{ fontWeight: 'bold' }}> Active Subscription </Box>
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body1">HackGATE™ Beta Plan</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button disabled variant="outlined" sx={{borderRadius: "8px"}}>Upgrade</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
            <CardContent>
              <Typography variant="h6" mb={2}>
              <Box sx={{ fontWeight: 'bold' }}>  Support Resources </Box>
              </Typography>
              <EuiLink
                href="https://www.hackgate.io/how-does-hackgate-work"
                target="_blank"
              >
                Summary
              </EuiLink>
              <EuiSpacer size="s" />
              <Typography>Documentation (Coming soon)</Typography>
              <EuiSpacer size="s" />
              <Typography>Feel free to ask at support@hckrt.com</Typography>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
            <CardContent>
              <Typography variant="h6" mb={2}>
              <Box sx={{ fontWeight: 'bold' }}>  API </Box>
              </Typography>
              <Typography variant="body1" mb={1}>
                Site ID
              </Typography>
              <TextField
                fullWidth
                id="siteID"
                defaultValue={site.id}
                inputRef={textFieldRef}
                InputProps={{
                  sx: {borderRadius: "8px"},
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopy}>
                        <ContentCopyOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
              />
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ borderColor: "red", borderRadius: "12px" }}>
            <CardContent>
              <Typography variant="h6" mb={2}>
              <Box sx={{ fontWeight: 'bold' }}>  Advanced Actions </Box>
              </Typography>
              {site.isActive === true && (
                <LoadingButton
                  loading={submitting}
                  onClick={() => submitDisable(site.id)}
                >
                  Stop immediately
                </LoadingButton>
              )}
              {site.isActive === false && (
                <LoadingButton
                  loading={submitting}
                  onClick={() => submitEnable(site.id)}
                >
                  Start immediately
                </LoadingButton>
              )}
              <Divider sx={{ mb: 0 }} />
              <LoadingButton
                loading={submitting}
                onClick={() => submitDeploy(site.id)}
                disabled={!site.isDeploymentProgress}
              >
                Force deploy
              </LoadingButton>
              <Divider sx={{ mb: 0 }} />
              <Button disabled>Transfer Site to another organization</Button>
              <Divider sx={{ mb: 0 }} />
              <Button disabled>Remove Site</Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
}
