import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Site } from "../../app/models/site";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { EuiBeacon, EuiButton } from "@elastic/eui";

interface Props {
  site: Site;
}

export default function SiteCard({ site }: Props) {
  return (
    <Card variant="outlined" sx={{borderRadius: "12px"}}>
      <CardContent>
        <Grid container gap={2}>
          <Grid
            item
            alignContent="center"
            justifyContent="center"
            paddingTop={1}
          >
            {site.isActive === true && (
              <>
                {" "}
                <EuiBeacon />{" "}
              </>
            )}
            {site.isActive === false && (
              <>
                {" "}
                <EuiBeacon color="danger" />{" "}
              </>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h5" component="div" mb={1}>
            <Box sx={{ fontWeight: 'bold' }}> {site.originName} </Box>
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
          {site.hackgatedName}
        </Typography>
          </Grid>
        </Grid>
 
        <Divider variant="middle" sx={{mb: 3}} />

        {site.isActive === true && (
          <>
            {" "}
            <Chip
              size="small"
              icon={<CheckCircleIcon />}
              color="success"
              label="Active"
              variant="filled"
              sx={{ mb: 2 }}
            />
          </>
        )}
        {site.isActive === false && (
          <>
            {" "}
            <Chip
              size="small"
              icon={<DoDisturbOnIcon />}
              color="error"
              label="Disabled"
              variant="filled"
              sx={{ mb: 2 }}
            />
          </>
        )}
        <br />
        {site.isDeploymentProgress === true && (
          <>
            <Chip
              size="small"
              icon={<PendingOutlinedIcon />}
              color="warning"
              label="Deployment is in progress"
              variant="outlined"
              sx={{ mb: 2 }}
            />
          </>
        )}
        {site.isDeploymentProgress === false && (
          <>
            <Chip
              size="small"
              icon={<CheckCircleIcon />}
              color="success"
              label="Deployed"
              variant="outlined"
              sx={{ mb: 2 }}
            />
          </>
        )}


        <Grid container>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Button
              component={Link}
              to={`/catalog/${site.id}`}
              variant="outlined"
              endIcon={<SettingsOutlinedIcon />}
              sx={{borderRadius: "8px"}}
            >
              Manage
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
