import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Chip, Divider, Grid, IconButton, useTheme } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import Footer from "./Footer";
import { grey } from "@mui/material/colors";
import { SignOutButton, UserButton, useUser } from "@clerk/clerk-react";
import { OrganizationSwitcher } from "@clerk/clerk-react";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { EuiSpacer } from "@elastic/eui";


const drawerWidth = 280;

interface Props {
  children: any;
}

const mainLinks = [
  { title: "Websites", path: "/catalog" },
  { title: "Analytics", path: "/analytics" },
];

const settingsLinks = [
  { title: "Organization", path: "/manage" },
  { title: "Account", path: "/profile" },
  { title: "Billing", path: "/billing" },
];

export default function Layout({ children }: Props) {
  const theme = useTheme();
  const { user } = useUser();

  return (
    <>
      <Box sx={{ display: "flex",  backgroundColor: "#fff", height:"100%" }}>
        {/* <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            borderBottom: 1,
            borderColor: theme.palette.divider,
            backdropFilter: "blur(30px)",
          }}
          color="inherit"
          elevation={0}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" alignItems="center">
              <OrganizationSwitcher hidePersonal />
            </Box>

            <Box display="flex" alignItems="center" sx={{}}>
              <Box sx={{ mr: 2 }}>
                <UserButton afterSignOutUrl="/login" />
              </Box>

              <Box>
                <Typography variant="body2" sx={{ fontSize: 15, mb: 0 }}>
                  Hi, {user?.fullName}
                </Typography>

                <Typography variant="caption" sx={{ color: "grey" }}>
                  {user?.primaryEmailAddress?.emailAddress}
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar> */}

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/catalog"
            >
              <Typography component="h1" variant="h5" sx={{ fontWeight: 600 }}>
                HackGATE™ <Chip label="Beta" variant="outlined" size="small" />
              </Typography>
            </Link>
          </Toolbar>

          <Toolbar sx={{mb:5}}>
            <OrganizationSwitcher hidePersonal />
          </Toolbar>

          <List
            sx={{
              paddingX: "16px",
              [`& .active, & .${listItemClasses.root}:hover`]: {
                color: "#F9FAFB",
                fontWeight: 500,
                borderRadius: "6px",
                backgroundColor: "#F2F4F7",
                "& svg": {
                  fill: grey[900],
                },
              },
            }}
          >
            {/* <ListSubheader component="div" id="nested-list-subheader" sx={{}}>
                <Typography mb={1}>MAIN MENU</Typography>
              </ListSubheader> */}
            {mainLinks.map(({ title, path }) => (
              <ListItem
                component={NavLink}
                to={path}
                key={path}
                disablePadding
                sx={{ borderRadius: "6px" }}
              >
                <ListItemButton sx={{ borderRadius: "6px" }}>
                  <ListItemIcon sx={{ minWidth: "36px" }}>
                    {title === "Websites" ? (
                      <WebOutlinedIcon sx={{ color: "#667085" }} />
                    ) : (
                      ""
                    )}
                    {title === "Analytics" ? (
                      <QueryStatsOutlinedIcon sx={{ color: "#667085" }} />
                    ) : (
                      ""
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "16 px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <EuiSpacer/>
          <List
            sx={{
              paddingX: "16px",
              [`& .active, & .${listItemClasses.root}:hover`]: {
                fontWeight: 500,
                borderRadius: "6px",
                backgroundColor: "#F2F4F7",
                "& svg": {
                  fill: grey[900],
                },
              },
            }}
          >
            {/* <ListSubheader component="div" id="nested-list-subheader"
               sx={{mt: 3}}>
                <Typography mb={1}>SETTINGS</Typography>
              </ListSubheader> */}
            {settingsLinks.map(({ title, path }) => (
              <ListItem
                component={NavLink}
                to={path}
                key={path}
                disablePadding
                sx={{ borderRadius: "6px" }}
              >
                <ListItemButton sx={{ borderRadius: "6px" }}>
                  <ListItemIcon sx={{ minWidth: "36px" }}>
                    {title === "Organization" ? (
                      <BusinessOutlinedIcon sx={{ color: "#667085" }} />
                    ) : (
                      ""
                    )}
                    {title === "Account" ? (
                      <PersonOutlineIcon sx={{ color: "#667085" }} />
                    ) : (
                      ""
                    )}
                    {title === "Billing" ? (
                      <AccountBalanceWalletOutlinedIcon
                        sx={{ color: "#667085" }}
                      />
                    ) : (
                      ""
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "16 px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Box
            sx={{
              width: "100%",
              position: "absolute",
              bottom: 0,
            }}
          >
            <Divider variant="middle" />
            <Grid container paddingX={2} paddingY={2} gap={2}>
              <Grid item marginTop={1}>
                <Box alignContent="center">
                  <UserButton afterSignOutUrl="/login" />
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="caption" fontWeight={600}>
                  {user?.fullName}
                </Typography>
                <br />
                <Typography variant="caption" >
                  {user?.primaryEmailAddress?.emailAddress}
                </Typography>
              </Grid>
              <Grid item>
                <SignOutButton>
                  <IconButton aria-label="logout">
                    <LogoutIcon />
                  </IconButton>
                </SignOutButton>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingTop: 5,
            paddingBottom: 2,
            backgroundColor: "#fff"
          }}
        >
          {children}
         <Footer/>
        </Box>
      </Box>
    </>
  );
}
