import agent from "../../app/api/agent";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { AnalyticsParams } from "../../app/models/analytics";

export default function MyChart2(params: AnalyticsParams) {
  const [trafficTable, setTrafficTable] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    let id = 0;
    agent.Analytics.getTrafficTable(params)
      .then((response) => {
        setTrafficTable(response);
        for (const bucket of response.aggregations["0"].buckets) {
          const name = bucket.key;
          for (const dateBucket of bucket["1"].buckets) {
            const date = new Date(dateBucket.key_as_string)
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
              .replace(",", "");
            const count = dateBucket.doc_count;
            tableData.push({ id: id++, name, date, count });
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [params]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "date", headerName: "Date", type: "date", flex: 1 },
    { field: "count", headerName: "Count", type: "number", flex: 1 },
  ];

  return (
    <>
      <Typography mb={2}>Most active users</Typography>
      <Box 
       display="flex"
       justifyContent="center"
       alignItems="center"
       sx={{ height: 400, width: "100%" }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            rows={tableData.filter((row) => row.count !== "0")}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              sorting: {
                sortModel: [{ field: "count", sort: "desc" }],
              },
            }}
            sx={{borderRadius: "12px"}}
          />
        )}
      </Box>
    </>
  );
}
