import { Typography } from "@mui/material";
import Layout from "../../app/layout/Layout";

export default function Privacy() {
  return (
    <Layout>
      <Typography variant="h4">Privacy</Typography>
    </Layout>
  );
}
