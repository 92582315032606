import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { CreateOrganization } from "@clerk/clerk-react";
import { Button } from "@mui/material";
import {
  EuiSpacer,
  EuiStepsHorizontal,
  EuiStepsHorizontalProps,
} from "@elastic/eui";

export default function CreateTenant() {
  const horizontalSteps: EuiStepsHorizontalProps["steps"] = [
    {
      title: "Organization creation",
      status: "current",
      onClick: () => {},
    },
    {
      title: "Add website to use HackGATE",
      onClick: () => {},
    },
    {
      title: "Add details",
      onClick: () => {},
    },
    {
      title: "Deployment",
      status: "disabled",
      onClick: () => {},
    },
  ];

  return (
    <>

      <Box
        sx={{
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" mb={5}>
          Set up your HackGATE
        </Typography>

        <EuiStepsHorizontal steps={horizontalSteps} />

        <EuiSpacer />

        <Typography mb={5}>
          The first step to use your HackGATE is enter your organization’s name.
          We will generate a unique ID for you, serving as the identifier for
          all members of your organization
        </Typography>
        <CreateOrganization afterCreateOrganizationUrl="/catalog/add-site" />

        <Typography mt={5} mb={2}>
          If you prefer not to create a new organization, instead, join an
          existing one.
        </Typography>
        <Button component={Link} variant="outlined" to="/catalog">
          Skip
        </Button>
      </Box>
    </>
  );
}
