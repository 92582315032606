import { EuiLink } from "@elastic/eui";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} mt={5}>
        <Box gridColumn="span 7">
          <Typography variant="body2" color="text.secondary">
            {"Copyright © "}
            {new Date().getFullYear() + " "}
            <Button
              component={Link}
              to="https://hckrt.com"
              sx={{
                textTransform: "none",
                color: "inherit",
                fontWeight: "bold",
              }}
            >
              Hackrate
            </Button>
            {" "}
            All rights reserved.
          </Typography>
        </Box>
        <Box gridColumn="span 5" justifySelf="end">
          <Typography variant="body2" color="text.secondary" mr={1}>
            support@hckrt.com
            {" "} | {" "} 
            <EuiLink
              href="https://www.hackgate.io/terms-conditions"
              target="_blank"
            >
              Terms & Conditions
            </EuiLink>
            {" "} | {" "} 
            <EuiLink
              href="https://www.hackgate.io/privacy-notice"
              target="_blank"
            >
              Privacy Policy
            </EuiLink>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
