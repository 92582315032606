import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "../../app/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/store/configureStore";
import { FieldValues, useForm } from "react-hook-form";
import { updateBilling } from "./billingSlice";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { BillingData } from "../../app/models/billing";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import BackButton from "../../app/layout/BackButton";

export default function BillingUpdate() {
  const [billingData, setBillingData] = useState<BillingData>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    agent.Billing.details()
      .then((billingData) => setBillingData(billingData))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <LoadingComponent message="Loading billing data..." />;

  async function submitForm(data: FieldValues) {
    try {
      await dispatch(updateBilling(data));
      navigate("/billing");
    } catch (error) {
      console.log("Error while updating billing informations");
    }
  }

  return (
    <Layout>
      <BackButton />
      <Typography variant="h4" mb={4}>
        Billing information update
      </Typography>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
            Billing email preference
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(submitForm)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  required
                  id="email"
                  type="email"
                  label="E-mail address"
                  autoComplete="email"
                  {...register("email", {
                    required: "E-mail address is required",
                  })}
                  error={!!errors.email}
                  helperText={errors?.email?.message?.toString()}
                  value={billingData?.email}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      email: e.target.value,
                      bussinessName: billingData?.bussinessName || "",
                      vat: billingData?.vat || "",
                      address: billingData?.address || "",
                      addressLine2: billingData?.addressLine2 || "",
                      city: billingData?.city || "",
                      zipCode: billingData?.zipCode || "",
                      country: billingData?.country || "",
                      state: billingData?.state || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  loading={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  variant="outlined"
                  sx={{borderRadius: "8px"}}
                >
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={4}>
            Billing address
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(submitForm)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                  id="businessName"
                  label="Business name"
                  {...register("bussinessName", {
                    required: "Business name is required",
                  })}
                  value={billingData?.bussinessName}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      bussinessName: e.target.value,
                      email: billingData?.email || "",
                      vat: billingData?.vat || "",
                      address: billingData?.address || "",
                      addressLine2: billingData?.addressLine2 || "",
                      city: billingData?.city || "",
                      zipCode: billingData?.zipCode || "",
                      country: billingData?.country || "",
                      state: billingData?.state || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                  id="address"
                  label="Address"
                  {...register("address", {
                    required: "Address is required",
                  })}
                  value={billingData?.address}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      address: e.target.value,
                      bussinessName: billingData?.bussinessName || "",
                      vat: billingData?.vat || "",
                      email: billingData?.email || "",
                      addressLine2: billingData?.addressLine2 || "",
                      city: billingData?.city || "",
                      zipCode: billingData?.zipCode || "",
                      country: billingData?.country || "",
                      state: billingData?.state || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />

                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="addressLine2"
                  label="Address line 2"
                  {...register("addressLine2", {})}
                  value={billingData?.addressLine2}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      addressLine2: e.target.value,
                      bussinessName: billingData?.bussinessName || "",
                      vat: billingData?.vat || "",
                      address: billingData?.address || "",
                      email: billingData?.email || "",
                      city: billingData?.city || "",
                      zipCode: billingData?.zipCode || "",
                      country: billingData?.country || "",
                      state: billingData?.state || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />

                <TextField
                  size="small"
                  margin="normal"
                  required
                  id="city"
                  label="City"
                  sx={{ mr: 2 }}
                  {...register("city", {
                    required: "City is required",
                  })}
                  value={billingData?.city}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      city: e.target.value,
                      bussinessName: billingData?.bussinessName || "",
                      vat: billingData?.vat || "",
                      address: billingData?.address || "",
                      addressLine2: billingData?.addressLine2 || "",
                      email: billingData?.email || "",
                      zipCode: billingData?.zipCode || "",
                      country: billingData?.country || "",
                      state: billingData?.state || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />

                <TextField
                  size="small"
                  margin="normal"
                  required
                  id="zipCode"
                  label="Postal/ ZIP code"
                  {...register("zipCode", {
                    required: "Postal/ ZIP code is required",
                  })}
                  value={billingData?.zipCode}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      zipCode: e.target.value,
                      bussinessName: billingData?.bussinessName || "",
                      vat: billingData?.vat || "",
                      address: billingData?.address || "",
                      addressLine2: billingData?.addressLine2 || "",
                      city: billingData?.city || "",
                      email: billingData?.email || "",
                      country: billingData?.country || "",
                      state: billingData?.state || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />

                <TextField
                  size="small"
                  margin="normal"
                  required
                  id="country"
                  label="Country/Region"
                  sx={{ mr: 2 }}
                  {...register("country", {
                    required: "Country is required",
                  })}
                  value={billingData?.country}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      country: e.target.value,
                      bussinessName: billingData?.bussinessName || "",
                      vat: billingData?.vat || "",
                      address: billingData?.address || "",
                      addressLine2: billingData?.addressLine2 || "",
                      city: billingData?.city || "",
                      zipCode: billingData?.zipCode || "",
                      email: billingData?.email || "",
                      state: billingData?.state || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />
                <TextField
                  size="small"
                  margin="normal"
                  required
                  id="state"
                  label="State/Province"
                  sx={{ mb: 3 }}
                  {...register("state", {
                    required: "State is required",
                  })}
                  value={billingData?.state}
                  onChange={(e) =>
                    setBillingData({
                      ...billingData,
                      state: e.target.value,
                      bussinessName: billingData?.bussinessName || "",
                      vat: billingData?.vat || "",
                      address: billingData?.address || "",
                      addressLine2: billingData?.addressLine2 || "",
                      city: billingData?.city || "",
                      zipCode: billingData?.zipCode || "",
                      country: billingData?.country || "",
                      email: billingData?.email || "",
                    })
                  }
                  InputProps={{
                    sx: {borderRadius: "8px"}
                  }}
                />
              </Grid>
            </Grid>
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <LoadingButton
                  loading={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  variant="outlined"
                  sx={{borderRadius: "8px"}}
                >
                  Update
                </LoadingButton>
              </Box>
          </Box>
        </CardContent>
      </Card>
    </Layout>
  );
}
