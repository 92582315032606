import Typography from "@mui/material/Typography";
import { SignIn } from "@clerk/clerk-react";
import { ThemeProvider } from "@emotion/react";
import { Box, Container, Grid, Link, Paper, createTheme } from "@mui/material";
import { Image } from "mui-image";

export default function Login() {
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", padding: "20px" }}>

        <Grid item xs={12} sm={5} md={5} component={Paper} elevation={0} square sx={{paddingX: 3, paddingY: 1}}>
        <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: 600 }}
            >
              HackGATE™
            </Typography>
          <Box
            sx={{
              paddingTop: 8,
              paddingBottom: 6,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Container component="main" maxWidth="xs">
              <Typography
                component="h1"
                variant="h5"
                mb={1}
                sx={{ fontWeight: 600, textAlign: "left" }}
              >
                Welcome back! 👋
              </Typography>
              <Typography variant="body2" mb={5}>
                Please enter your credentials to sign in!
              </Typography>

              <SignIn
                redirectUrl="/onboarding/checkUser"
                signUpUrl="/register"
              />
            </Container>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          sx={{
            borderRadius: "20px",
            marginBottom: "12px",
            background: 'linear-gradient(45deg, #7915D6 30%, #754FC6 90%)', // Gradient background
          }}
        >
          <Box
          
            sx={{
              py: 8,
              mx: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >


                <Image width={"66%"} src="/img/login_illustration_2.png" />

            <Typography
              component="h1"
              variant="h4"
              mb={2}
              mt={3}
              sx={{ fontWeight: 600, textAlign: "center", color: "white" }}
            >
         Introducing HackGATE Dashboard
            </Typography>
            <Typography variant="body1" color="white" mb={5} textAlign="center">
            Analytics dashboards are designed to help you to gain better understanding of you penetration test projects by providing oversight of ethical hacker activity.            </Typography>

            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Image width={75} src="https://images.g2crowd.com/uploads/report_medal/image/1004327/medal.svg" />
              </Grid>
              <Grid item>
                <Image width={75} src="https://images.g2crowd.com/uploads/report_medal/image/1004387/medal.svg" />
              </Grid>
              <Grid item>
                <Image width={75} src="https://images.g2crowd.com/uploads/report_medal/image/1004379/medal.svg" />
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body2" color="white" align="center">
            {"Copyright © "}
            {new Date().getFullYear() + " "}
            <Link
              color="inherit"
              href="https://hckrt.com/"
              target="_blank"
              underline="hover"
              sx={{ fontWeight: "bold" }}
            >
              Hackrate
            </Link>{" "}
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
