import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSiteAsync, siteSelectors, startSite } from "./catalogSlice";
import { useEffect, useState } from "react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { FieldValues, useForm } from "react-hook-form";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

export default function CustomHackerListForm() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const site = useAppSelector((state) => siteSelectors.selectById(state, id!));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "all",
  });

  async function submitOptionForm(data: FieldValues) {
    agent.Catalog.allowedHackerType(data);
    toast.success("Allowed hacker types updated successfully!");
    dispatch(fetchSiteAsync(id!));
  }

  const handleChange = () => {
   // Toggle the local state
   setChecked(!checked);
   // Update the form state using setValue
   setValue("AllowedHackerType", !checked);
  };

  useEffect(() => {
    // Set the initial value for the "Hacker" field
    setValue("AllowedHackerType", site?.hasCustomList!);
  }, [setValue, site]);

  const [checked, setChecked] = useState<boolean>(site?.hasCustomList!);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submitOptionForm)}
      id="form1"
      noValidate
      sx={{ mt: 1 }}
    >

      <Grid container alignItems="center" spacing={3} mb={3}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              id="SiteId"
              value={id}
              {...register("Id", { required: "Site id is required" })}
              sx={{ display: "none" }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  {...register("AllowedHackerType")}
                  onChange={handleChange}
                  value={checked}
                />
              }
              label="Custom List"
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
