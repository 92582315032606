import { ContentCopyOutlined } from "@mui/icons-material";
import { Card, CardContent, Typography, TextField, InputAdornment, IconButton, Box } from "@mui/material";
import Layout from "../../app/layout/Layout";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { OrganizationProfile, useOrganization } from "@clerk/clerk-react";
import { EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import { useRef } from "react";
import { toast } from "react-toastify";

export default function Members() {
  const { organization } = useOrganization();

  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const handleCopy = () => {
    const textFieldValue = textFieldRef.current?.value;
    if (textFieldValue) {
      navigator.clipboard.writeText(textFieldValue);
      toast.success("Copied to clipboard");
    }
  };

  if (organization?.id == null)
    return (
      <LoadingComponent message="Please select or create an organization" />
    );

  return (
    <Layout>
        <EuiSpacer size="xl"/>
      <OrganizationProfile />


      <Card variant="outlined" sx={{ mb: 2, mt: 5, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={2}>
          <Box sx={{ fontWeight: 'bold' }}> API </Box>
          </Typography>
          <Typography variant="body1" mb={1}>
            Organization ID
          </Typography>
          <TextField
            fullWidth
            id="orgId"
             defaultValue={organization.id}
            inputRef={textFieldRef}
            InputProps={{
              sx: {borderRadius: "8px"},
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy}>
                    <ContentCopyOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          />
        </CardContent>
      </Card>
    </Layout>
  );
}
