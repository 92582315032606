import agent from "../../app/api/agent";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { AnalyticsParams } from "../../app/models/analytics";

export default function MyChart(params: AnalyticsParams) {
  const [pathDiscovery, setPathDiscovery] = useState<any>();
  const [buckets, setBuckets] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    agent.Analytics.getPathDiscovery(params)
      .then((response) => {
        const bucketsData = response?.aggregations?.[0]?.buckets || [];
        setBuckets(bucketsData);
        setPathDiscovery(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [params]);

  const columns: GridColDef[] = [
    { field: "key", headerName: "Path", flex: 1 },
    { field: "doc_count", headerName: "Count", type: "number", width: 100 },
  ];

  return (
    <>
      <Typography mb={2}>Path Discovery</Typography>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: 400, width: "100%" }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            rows={buckets}
            columns={columns}
            getRowId={(row) => row.key}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              sorting: {
                sortModel: [{ field: "doc_count", sort: "desc" }],
              },
            }}
            sx={{borderRadius: "12px"}}
          />
        )}
      </Box>
    </>
  );
}
