import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { addHacker, fetchSiteAsync, siteSelectors } from "./catalogSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import Layout from "../../app/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Member } from "../../app/models/member";
import agent from "../../app/api/agent";
import BackButton from "../../app/layout/BackButton";
import { LoadingButton } from "@mui/lab";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EuiBreadcrumb, EuiBreadcrumbs, EuiSpacer } from "@elastic/eui";
import CustomHackerListForm from "./CustomHackerListForm";

export default function Hackers() {
 
  function ListForm() {
    return(
      <></>
    )
  }
  

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const site = useAppSelector((state) => siteSelectors.selectById(state, id!));
  const { status: siteStatus } = useAppSelector((state) => state.catalog);
  const [hackers, setHackers] = useState<Member[]>([]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "all",
  });

  async function submitForm(data: FieldValues) {
    try {
      await dispatch(addHacker(data));
      toast.success("Hacker added successfully");
    } catch (error) {
      console.log("Error adding new hacker");
    }
  }

  const [loading, setLoading] = useState(true);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 200, hide: true },
    { field: "email", headerName: "E-mail address", flex: 1 },
    {
      field: "delete",
      headerName: "Action",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <LoadingButton
          loading={isSubmitting}
          type="submit"
          variant="outlined"
          color="error"
          onClick={() => handleDelete(params.row.id)}
        >
          Remove
        </LoadingButton>
      ),
    },
  ];

  useEffect(() => {
    if (!site) dispatch(fetchSiteAsync(id!));
  }, [id, site, dispatch]);

  useEffect(() => {
    agent.Catalog.getHackers(id!)
      .then((hackers) => setHackers(hackers))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [isSubmitting]);

  if (loading) return <LoadingComponent message="Loading hacker list..." />;

  if (siteStatus.includes("pending"))
    return <LoadingComponent message="Loading website..." />;

  if (!site) return <h3>Website not found</h3>;

  const rows = hackers;

  const handleDelete = async (hackerid: any) => {
    agent.Catalog.removeHacker({ id: hackerid })
      .then(() => deleteHackerFromList(hackerid))
      .finally(() => toast.success("Hacker removed successfully"));
  };

  const deleteHackerFromList = (id: any) => {
    const filteredValues = hackers.filter((item) => item.id !== id);
    setHackers(filteredValues);
  };

  const breadcrumbs: EuiBreadcrumb[] = [
    {
      text: 'Websites',
      href: 'catalog/',
      onClick: (e) => {
        e.preventDefault();
      },
      'data-test-subj': 'breadcrumbsAnimals',
    },
    {
      text: site?.originName,
      href: '/',
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      text: 'Invite Hackers',
      href: '#',
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ];

  return (
    <Layout>
      <BackButton />
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />
      <EuiSpacer size="xl" />
      <Typography variant="h4" mb={2}>
        Allowed Ethical Hackers
      </Typography>

      <CustomHackerListForm/>

      <Divider sx={{ mb: 4 }}>
        <Chip label="If allowed hackers is from custom list" />
      </Divider>

      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ height: 400 }}>
            <DataGrid
              sx={{borderRadius: "12px"}}
              getRowId={(row) => row.email}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        component="form"
        onSubmit={handleSubmit(submitForm)}
        noValidate
        sx={{ mt: 3 }}
        id="form2"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              id="SiteId"
              value={id}
              {...register("SiteId", { required: "Site id is required" })}
              sx={{ display: "none" }}
            />
            <TextField
              fullWidth
              label="E-mail address of an Ethical Hacker"
              size="small"
              id="email"
              type="email"
              {...register("email", {
                required: "Hacker email address is required",
              })}
            />
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              loading={isSubmitting}
              disabled={!isValid}
              type="submit"
              fullWidth
              variant="contained"
            >
              Add Hacker
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
