import { useState, useEffect } from "react";
import agent from "../../app/api/agent";
import { AnalyticsParams } from "../../app/models/analytics";
import {
  AccessorFn,
  Axis,
  BarSeries,
  Chart,
  Position,
  SeriesNameConfigOptions,
  SeriesNameFn,
  Settings,
  timeFormatter,
} from "@elastic/charts";
import { EuiCard, EuiFlexGroup, EuiLoadingChart } from "@elastic/eui";
import { Box } from "@mui/material";

export default function MyChart6(params: AnalyticsParams) {
  interface ChartProps {
    data: any; // Your Elasticsearch JSON response
  }

  const [mostActiveUsers, setMostActiveUsers] = useState<any>();
  const [buckets, setBuckets] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [seriesData, setSeriesData] = useState<any[]>([]);

  const [formattedData, setFormattedData] = useState<any[]>([]);

  const [legendData, setLegendData] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    agent.Analytics.getTrafficTimeline(params)
      .then((response) => {
        const seriesData = response.aggregations["0"].buckets.map(
          (bucket: { [x: string]: { buckets: any[] }; key: any }) => ({
            name: bucket.key,
            data: bucket["1"].buckets.map(
              (dateBucket: { [x: string]: { value: any }; key: any }) => ({
                x: dateBucket.key,
                y: dateBucket["2"].value,
              })
            ),
          })
        );

        const flattenedData = seriesData.flatMap(
          (series: { data: any }) => series.data
        );

        const uniqueDates = [
          ...new Set(flattenedData.map((point: { x: any }) => point.x)),
        ];
        const formattedData = uniqueDates.map((date) => ({
          x: date,
          ...seriesData.reduce(
            (acc: any, series: { name: any; data: any[] }) => ({
              ...acc,
              [series.name]:
                series.data.find((point: { x: unknown }) => point.x === date)
                  ?.y || 0,
            }),
            {}
          ),
        }));

        const legendData = seriesData.map((series: { name: any }) => ({
          id: series.name,
          label: series.name,
        }));

        setSeriesData(seriesData);
        setFormattedData(formattedData);
        setLegendData(legendData);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [params]);

  const dateFormatter = timeFormatter("YYYY-MM-DD HH:mm:ss");

  if (loading)
    return (
      <Box
        height={300}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <EuiFlexGroup alignItems="center" justifyContent="spaceAround">
          <EuiLoadingChart size="xl" />
        </EuiFlexGroup>
      </Box>
    );

  return (
    <>
      <Chart size={{ height: 300 }}>
        <Settings showLegend={true} legendPosition={Position.Right} />
        <Axis
          id="bottom"
          position={Position.Bottom}
          title="Date"
          tickFormat={dateFormatter}
        />
        <Axis
          id="left"
          title="Count of requests"
          position={Position.Left}
          tickFormat={(d) => Number(d).toLocaleString()}
        />

        {seriesData.map((series) => (
          <BarSeries
            key={series.name.toString()} // Explicitly convert to string
            id={series.name}
            name={series.name}
            data={formattedData}
            xAccessor="x"
            xScaleType="time"
            yAccessors={[series.name]}
            stackAccessors={["x"]}
          />
        ))}
      </Chart>
    </>
  );
}
