import { useForm, FieldValues } from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/store/configureStore";
import { addSite } from "../catalog/catalogSlice";
import Layout from "../../app/layout/Layout";
import { Card, CardContent, CardHeader, Divider, Grid, Icon } from "@mui/material";
import { EuiStepsHorizontal, EuiStepsHorizontalProps } from "@elastic/eui";
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function AddSite() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "all",
  });

  async function submitForm(data: FieldValues) {
    try {
      const response = await dispatch(addSite(data));
  
      // Check if the response indicates success 
      if (response && response.meta.requestStatus == "fulfilled") {
        navigate("/catalog");
      } else {
        console.log("Failed to add site");
      }
    } catch (error) {
      console.log("Error adding new site");
    }
  }

  const horizontalSteps: EuiStepsHorizontalProps['steps'] = [
    {
      title: 'Organization creation',
      status: 'complete',
      onClick: () => {},
    },
    {
      title: 'Add website to use HackGATE',
      status: 'current',
      onClick: () => {},
    },
    {
      title: 'Add details',
      onClick: () => {},
    },
    {
      title: 'Deployment',
      status: 'disabled',
      onClick: () => {},
    },
  ];

  return (
    <Layout>

      <EuiStepsHorizontal steps={horizontalSteps} />

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "60 %"
        }}
      >
        <Typography component="h1" variant="h5" textAlign="center" mb={3}>
          Start using HackGATE™
        </Typography>
        <Typography>
          To get started with HackGATE, simply input the website you want to integrate with our service, and we'll generate a unique HackGATE URL for you.
        </Typography>
        <Typography mb={5}>
        Please ensure you use the FQDN format, such as https://app.mycompany.com.
        </Typography>
        
        <Box
          component="form"
          onSubmit={handleSubmit(submitForm)}
          noValidate
          sx={{ mt: 1 }}
          alignItems= "center"
          alignContent= "center"
          justifyContent= "center"
        >

          <TextField
            margin="normal"
            required
            fullWidth
            id="originName"
            label="Enter your site (https://test.mycompany.com)"
            autoComplete="originName"
            autoFocus
            {...register("originName", { required: "Site is required" })}
            error={!!errors.originName}
            helperText={errors?.originName?.message?.toString()}
            InputProps={{
              sx: {borderRadius: "8px"}
            }}
          />

          <Typography mt={5}>
          You'll be enrolled in our Free Plan, offering essential features at no cost.
          </Typography>
          <Typography mb={5}>
          You can upgrade your plan at any time.
          </Typography>

          <Card variant="outlined" sx={{borderRadius: "12px", borderWidth: "2px", width: "75%"}}>
            <CardContent>
              <Grid container>
              <Grid item xs={2}>
                <Icon fontSize="large">
                  <StarPurple500Icon fontSize="large"/>
                </Icon>
                </Grid>
                <Grid item xs={10}>
                <Typography variant="h6" mb={1}>
              <Box sx={{ fontWeight: 'bold' }}> HackGATE™ Beta Plan
              </Box>
              </Typography>
              <Divider/>
              <Typography variant="h4" mt={2} mb={2}>Free</Typography>
              <Typography> This plan includes...</Typography>
              <Typography> <Icon><CheckCircleOutlineIcon fontSize="small"/></Icon>Access to all basic features</Typography>
              <Typography> <Icon><CheckCircleOutlineIcon fontSize="small"/></Icon>Basic reporting and analytics</Typography>
              <Typography> <Icon><CheckCircleOutlineIcon fontSize="small"/></Icon>Chat and email support</Typography>
                </Grid>
              </Grid>
            
            </CardContent>
          </Card>

          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2, borderRadius: "8px" }}
          >
            Add Site
          </LoadingButton>
        </Box>
      </Box>
    </Layout>
  );
}
