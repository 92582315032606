import agent from "../../app/api/agent";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AnalyticsParams } from "../../app/models/analytics";

const formattedString = Number("202505").toLocaleString("en-US", {
  useGrouping: true,
});

export default function MyChart4(params: AnalyticsParams) {
  const [totalRequests, setTotalRequests] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    agent.Analytics.getAnalytics(params)
      .then((totalRequests) => setTotalRequests(totalRequests))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [params]);

  return (
    <>
      <Typography>Number of total authenticated requests</Typography>
      <Box
        sx={{
          height: 400,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <CircularProgress size={40} />
        ) : (
          <Typography variant="h1">
            {Number(totalRequests.hits.total.value).toLocaleString("en-us")}
          </Typography>
        )}
        <Typography variant="subtitle1" textAlign={"center"}>
          HTTP requests
        </Typography>
      </Box>
    </>
  );
}
