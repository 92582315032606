import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";
import agent from "../../app/api/agent";
import { BillingData } from "../../app/models/billing";
import { RootState } from "../../app/store/configureStore";


const billingAdapter = createEntityAdapter<BillingData>();

interface BillingState {
    billingLoaded: boolean;
    status: string;
}

export const updateBilling = createAsyncThunk<string, FieldValues>(
    'billing/update',
    async (data, thunkAPI) => {
        try {
            const billing = await agent.Billing.update(data);
            return billing;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)


export const billingSlice = createSlice({
    name: 'billing',
    initialState: billingAdapter.getInitialState<BillingState>({
        billingLoaded: false,
        status: 'idle',
    }),
    reducers: {
        
    },
    extraReducers: (builder => {
      
    })
})

export const billingSelectors = billingAdapter.getSelectors((state: RootState) => state.billing);
