import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Layout from "./Layout";

interface Props {
  message?: string;
}

export default function LoadingComponent({ message = "Loading..." }: Props) {
  return (
    <Layout>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress size={40} />
          <Typography
            variant="h5"
            sx={{ justifyContent: "center", position: "fixed", top: "60%" }}
          >
            {message}
          </Typography>
        </Box>
    </Layout>
  );
}

export function LoadingComponentFullPage({ message = "Loading..." }: Props) {
  return (
      <Backdrop open={true} invisible={true}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress size={40} />
          <Typography
            variant="h5"
            sx={{ justifyContent: "center", position: "fixed", top: "60%" }}
          >
            {message}
          </Typography>
        </Box>
      </Backdrop>
  );
}
