import agent from "../../app/api/agent";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AnalyticsParams } from "../../app/models/analytics";

import {
  EuiFlexGroup,
  EuiLoadingChart,
  htmlIdGenerator,
  useEuiTheme,
} from "@elastic/eui";
import {
  Chart,
  Partition,
  Settings,
  PartitionLayout,
  PartialTheme,
} from "@elastic/charts";
import {
  EUI_CHARTS_THEME_DARK,
  EUI_CHARTS_THEME_LIGHT,
} from "@elastic/eui/dist/eui_charts_theme";

import "@elastic/charts/dist/theme_light.css";

export default function MyChart3(params: AnalyticsParams) {
  const [mostActiveUsers, setMostActiveUsers] = useState<any>();
  const [buckets, setBuckets] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    agent.Analytics.getMostActiveUsers(params)
      .then((response) => {
        const bucketsData = response?.aggregations?.[0]?.buckets || [];
        setBuckets(bucketsData);
        setMostActiveUsers(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [params]);

  const STATUS_DATA = [
    {
      key: "",
      doc_count: 0,
    },
  ];

  const { colorMode } = useEuiTheme();
  const htmlId = htmlIdGenerator();
  const exampleOne = htmlId();

  /**
   * Setup theme based on current light/dark theme
   */
  const isDarkTheme = colorMode === "DARK";
  const euiChartTheme = isDarkTheme
    ? EUI_CHARTS_THEME_DARK
    : EUI_CHARTS_THEME_LIGHT;

  const themeOverrides: PartialTheme = {
    partition: { emptySizeRatio: 0.4 },
  };

  if (loading)
    return (
      <Box
        height={400}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <EuiFlexGroup alignItems="center" justifyContent="spaceAround">
          <EuiLoadingChart size="xl" />
        </EuiFlexGroup>
      </Box>
    );

  return (
    <div>
      <Typography mb={2}>Most active users</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ height: 400, width: "100%" }}>
            <Chart size={{ height: 375 }}>
              <Settings
                theme={[themeOverrides, euiChartTheme.theme]}
                ariaLabelledBy={exampleOne}
                showLegend
              />
              <Partition
                id="pieByPR"
                data={buckets}
                layout={PartitionLayout.sunburst}
                valueAccessor={(d) => d.doc_count}
                layers={[
                  {
                    groupByRollup: (d: (typeof STATUS_DATA)[0]) => d.key,
                    shape: {
                      fillColor: (_, sortIndex) =>
                        euiChartTheme.theme.colors!.vizColors![sortIndex],
                    },
                  },
                ]}
                clockwiseSectors={false}
              />
            </Chart>
          </Box>
        </>
      )}
    </div>
  );
}
