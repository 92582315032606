import {
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import Layout from "../../app/layout/Layout";
import { ContentCopyOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useRef } from "react";
import { UserProfile,  useUser} from "@clerk/clerk-react";
import { EuiSpacer } from "@elastic/eui";


export default function Profile() {

  const {user} = useUser();

  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const handleCopy = () => {
    const textFieldValue = textFieldRef.current?.value;
    if (textFieldValue) {
      navigator.clipboard.writeText(textFieldValue);
      toast.success("Copied to clipboard");
    }
  };

  return (
    <Layout>
      <EuiSpacer size="xl"/>
      <UserProfile />

      <Card variant="outlined" sx={{ mb: 2, mt: 5, borderRadius: "12px" }}>
        <CardContent>
          <Typography variant="h6" mb={2}>
          <Box sx={{ fontWeight: 'bold' }}> API </Box>
          </Typography>
          <Typography variant="body1" mb={1}>
            Account ID
          </Typography>
          <TextField
            fullWidth
            id="siteID"
             defaultValue={user?.id}
            inputRef={textFieldRef}
            InputProps={{
              sx: {borderRadius: "8px"},
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy}>
                    <ContentCopyOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          />
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
        <CardContent>
        <Typography variant="h6" mb={2}>
          <Box sx={{ fontWeight: 'bold' }}> Timezone </Box>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body1">
                GMT-00:00 - Coordinated Universal Time
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" disabled sx={{borderRadius: "8px"}}>
                Change
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
}
